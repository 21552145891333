import React, { useEffect, useState } from "react"
import { useAuth } from "@auth/useAuth"
import SEO from "@components/seo"
import Layout from "@components/layout"
import UserForm from "./_user_form"
import { toast } from "react-toastify"
import get from "lodash/get"

const EditUserPage = ({ location }) => {
  const { makeRequest, isAuthenticated } = useAuth() || {}

  const [existingUser, setEu] = useState({ id: "" })

  useEffect(() => {
    if (!isAuthenticated) return
    const paramsString = get(location, "search") || ""
    let searchParams = new URLSearchParams(paramsString)
    // const params = location && location.search ? qs.parse(location.search) : {}
    if (searchParams.get("user_id")) {
      makeRequest("users/view", {
        id: searchParams.get("user_id"),
      }).then((res) => {
        if (res.user && res.user.id) {
          setEu({
            name: res.user.name || "",
            id: res.user.id,
            space_operator_id: res.user.space_operator_id || "",
            email: res.user.email || "",
            user_type: res.user.user_type,
            assigned_location_ids: res.user.assigned_location_ids,
          })
        } else {
          if (res.user && res.user.detail) {
            toast.error("Failed: " + res.user.detail)
          } else {
            toast.error("Couldn't fetch the user")
          }
        }
      })
    }
  }, [isAuthenticated])

  return (
    <>
      <SEO title={"Edit user"} />
      <Layout title={"Edit user"} progress={!existingUser.id}>
        <UserForm buttonLabel={"Update"} existingUser={existingUser} />
      </Layout>
    </>
  )
}

export default EditUserPage
